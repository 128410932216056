const INIT_WEB3: string = 'INIT_WEB3';
const ERROR_WEB3: string = 'ERROR_WEB3';
const CREATE_TOKEN: string = 'CREATE_TOKEN';
const MINT_TOKEN: string = 'MINT_TOKEN';
const TRANSFER_TOKEN: string = 'TRANSFER_TOKEN';
const GET_ALL_TOKEN: string = 'GET_ALL_TOKEN';
const GET_TOKEN_DETAIL: string = 'GET_TOKEN_DETAIL';
const GET_TOKEN_BALANCE: string = 'GET_TOKEN_BALANCE';
const GET_ALL_CROWDSALE: string = 'GET_ALL_CROWDSALE';
const TOKEN_LOADING: string = 'TOKEN_LOADING';
const TXN_LOADING: string = 'TXN_LOADING';
const GET_TRANSACTION_HISTORY: string = 'GET_TRANSACTION_HISTORY';
const GET_CROWDSALE_DATA: string = 'GET_CROWDSALE_DATA';

export {
  INIT_WEB3,
  MINT_TOKEN,
  TRANSFER_TOKEN,
  CREATE_TOKEN,
  GET_ALL_TOKEN,
  GET_TOKEN_DETAIL,
  GET_TOKEN_BALANCE,
  ERROR_WEB3,
  TOKEN_LOADING,
  TXN_LOADING,
  GET_TRANSACTION_HISTORY,
  GET_ALL_CROWDSALE,
  GET_CROWDSALE_DATA,
};
